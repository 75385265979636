import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import styles from './styles';

const { palette } = lightTheme;

// Util to control background color complete section
export const getBackgroundStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const background: Record<string, SxProps> = {
    whiteLeft: {
      backgroundColor: palette.grey['50']
    },
    whiteRight: {
      backgroundColor: palette.grey['50']
    },
    greenLeft: {
      background:
        'radial-gradient(59.97% 107.94% at 0% 100%, #01713B 0%, #017163 100%)'
    },
    greenRight: {
      background:
        'radial-gradient(59.97% 107.94% at 0% 100%, #01713B 0%, #017163 100%)'
    }
  };

  return { ...background[variant], ...styles.base };
};

export const getMobileImageWrapperStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const layout: Record<string, SxProps> = {
    whiteLeft: {
      color: 'red',
      ...styles.mainImgMobile,
      ...styles.mobileImageWrapperLeft
    },
    whiteRight: {
      color: 'red',
      ...styles.mainImgMobile,
      ...styles.mobileImageWrapperRight
    },
    greenLeft: {
      color: 'red',
      ...styles.mainImgMobile,
      ...styles.mobileImageWrapperLeft
    },
    greenRight: {
      color: 'red',
      ...styles.mainImgMobile,
      ...styles.mobileImageWrapperRight
    }
  };

  return { ...layout[variant] };
};

// Utils to control layout of grid items
export const getLayoutStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const layout: Record<string, SxProps> = {
    whiteLeft: {
      ...styles.gridLeft
    },
    whiteRight: {
      ...styles.gridRight
    },
    greenLeft: {
      ...styles.gridLeft
    },
    greenRight: {
      ...styles.gridRight
    }
  };

  return { ...layout[variant] };
};

// Utils to control large clipped image placement
export const getImgLayoutStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const layout: Record<string, SxProps> = {
    whiteLeft: {
      ...styles.largeImgLeft
    },
    whiteRight: {
      ...styles.largeImgRight
    },
    greenLeft: {
      ...styles.largeImgLeft
    },
    greenRight: {
      ...styles.largeImgRight
    }
  };

  return { ...layout[variant] };
};

// Utils to apply styles to titles
export const getFontTitleStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const fontTitle: Record<string, SxProps> = {
    whiteLeft: {
      color: palette.primary.main
    },
    whiteRight: {
      color: palette.primary.main
    },
    greenLeft: {
      color: palette.secondary.main
    },
    greenRight: {
      color: palette.secondary.main
    }
  };

  return { ...styles.titleText, ...fontTitle[variant] };
};

// Utils to apply styles to subtitle
export const getFontsubTitleStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const fontSubtitle: Record<string, SxProps> = {
    whiteLeft: {
      color: palette.grey.main
    },
    whiteRight: {
      color: palette.grey.main
    },
    greenLeft: {
      color: palette.grey['50']
    },
    greenRight: {
      color: palette.grey['50']
    }
  };

  return { ...styles.subtitleText, ...fontSubtitle[variant] };
};

// Utils to apply styles to description
export const getFontDescriptionStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const fontDescription: Record<string, SxProps> = {
    whiteLeft: {
      color: palette.grey.main
    },
    whiteRight: {
      color: palette.grey.main
    },
    greenLeft: {
      color: palette.grey['50']
    },
    greenRight: {
      color: palette.grey['50']
    }
  };

  return { ...styles.descriptionText, ...fontDescription[variant] };
};

// Util to control color of tickmark icon
export const getBulletIconStyle = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const iconStyle: Record<string, SxProps> = {
    whiteLeft: {
      svg: {
        width: '1.75rem',
        height: '1.75rem',
        fill: palette.primary.main
      }
    },
    whiteRight: {
      svg: {
        width: '1.75rem',
        height: '1.75rem',
        fill: palette.primary.main
      }
    },
    greenLeft: {
      svg: {
        width: '1.75rem',
        height: '1.75rem',
        // TODO: verify this change in any other variant from design
        // fill: palette.grey['50']
        fill: palette.secondary.main
      }
    },
    greenRight: {
      svg: {
        width: '1.75rem',
        height: '1.75rem',
        fill: palette.grey['50']
      }
    }
  };

  return {
    ...styles.bulletPointBox,
    ...iconStyle[variant]
  };
};

// Utils to control styling of bullet text
export const getBulletTextStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const bulletText: Record<string, SxProps> = {
    whiteLeft: {
      color: palette.grey.main
    },
    whiteRight: {
      color: palette.grey.main
    },
    greenLeft: {
      color: palette.grey['50']
    },
    greenRight: {
      color: palette.grey['50']
    }
  };

  return { ...styles.bulletText, ...bulletText[variant] };
};

// Utils to control styling of anchor-links
export const getAnchorTextStyles = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const bulletText: Record<string, SxProps> = {
    whiteLeft: {
      color: palette.primary.main,
      textDecoration: 'underline'
    },
    whiteRight: {
      color: palette.primary.main,
      textDecoration: 'underline'
    },
    greenLeft: {
      color: palette.grey['50'],
      textDecoration: 'underline'
    },
    greenRight: {
      color: palette.grey['50'],
      textDecoration: 'underline'
    }
  };

  return { ...styles.bulletText, ...bulletText[variant] };
};

// TODO: Remove this utli when non-richtext elements removed
// Utils to control styling of bullet text (non-rich-text)
export const getIconBoxStyle = (
  variant: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight'
) => {
  const iconStyle: Record<string, SxProps> = {
    whiteLeft: {
      '.iconTickMark': {
        fill: palette.secondary.main
      }
    },
    whiteRight: {
      '.iconTickMark': {
        fill: palette.secondary.main
      }
    },
    greenLeft: {
      '.iconTickMark': {
        fill: palette.primary.main
      }
    },
    greenRight: {
      '.iconTickMark': {
        fill: palette.primary.main
      }
    }
  };

  return { ...styles.bulletPointsContainer, ...iconStyle[variant] };
};
