import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Link from 'next/link';
import Markdown from 'markdown-to-jsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image from 'next/image';
import { Button } from '../../components/Button';
import markdownOverrides from '../../utils/MarkdownOverrides';
import {
  getBackgroundStyles,
  getLayoutStyles,
  getImgLayoutStyles,
  getMobileImageWrapperStyles,
  getFontTitleStyles,
  getFontsubTitleStyles,
  getFontDescriptionStyles,
  getBulletIconStyle,
  getBulletTextStyles,
  getAnchorTextStyles,
  getIconBoxStyle
} from './utility';
import styles from './styles';

export interface LibFindingHomeProps {
  richTextContent?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  pointsArr?: Array<{ text: string; id: string; icon?: React.ReactElement }>;
  img?: string;
  imgAltText?: string;
  buttons?: {
    href?: string;
    label?: string;
    target?: string;
    variant?: string;
  }[];
  HorizontalSectionClippedImage;
  variant?: 'whiteLeft' | 'whiteRight' | 'greenLeft' | 'greenRight';
  children?: React.ReactNode;
}

function BulletPoint({ children, variant }: LibFindingHomeProps) {
  return (
    <Box sx={getBulletIconStyle(variant)}>
      <CheckCircleIcon />
      <Typography variant="body1" sx={getBulletTextStyles(variant)}>
        {children}
      </Typography>
    </Box>
  );
}

function LibFindingHome({
  richTextContent,
  title,
  subtitle,
  description,
  pointsArr,
  img,
  imgAltText = 'alt-text',
  variant = 'whiteLeft',
  buttons
}: LibFindingHomeProps) {
  const overrides = {
    ...markdownOverrides,
    h1: {
      component: Typography,
      props: { variant: 'h1', sx: getFontTitleStyles(variant) }
    },
    h2: {
      component: Typography,
      props: { variant: 'h2', sx: getFontTitleStyles(variant) }
    },
    h3: {
      component: Typography,
      props: { variant: 'h3', sx: getFontsubTitleStyles(variant) }
    },
    h4: {
      component: Typography,
      props: { variant: 'h4', sx: getFontsubTitleStyles(variant) }
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        sx: getFontDescriptionStyles(variant)
      }
    },
    a: {
      component: Link,
      props: { sx: getAnchorTextStyles(variant) }
    },
    li: {
      component: (props) => BulletPoint({ ...props, variant })
    },
    ul: {
      component: Box
    }
  };

  return (
    <Box
      component="section"
      sx={getBackgroundStyles(variant)}
      id="horizontalSectionClippedImg"
    >
      <Grid container alignItems="stretch" sx={getLayoutStyles(variant)}>
        <Grid item xs={12} md={6} sx={styles.containerText}>
          <Box sx={styles.containerLeft} className={`variant--${variant}`}>
            <Box sx={getMobileImageWrapperStyles(variant)}>
              <Image
                src={img}
                alt={imgAltText}
                width="0"
                height="0"
                sizes="100vw"
                loading="lazy"
              />
            </Box>
            {richTextContent && (
              <Markdown options={{ overrides }}>{richTextContent}</Markdown>
            )}
            {title ? (
              <Typography variant="h2" sx={getFontTitleStyles(variant)}>
                {title}
              </Typography>
            ) : null}
            {subtitle ? (
              <Typography variant="body1" sx={getFontsubTitleStyles(variant)}>
                {subtitle}
              </Typography>
            ) : null}
            {description && (
              <Typography
                variant="body1"
                sx={getFontDescriptionStyles(variant)}
              >
                {description}
              </Typography>
            )}
            {pointsArr && pointsArr.length > 0 && (
              <Box sx={getIconBoxStyle(variant)}>
                {pointsArr.map(({ text, id }) => (
                  <Box sx={styles.bulletPointContainer} key={id}>
                    <CheckCircleIcon className="iconTickMark" />
                    <Typography sx={getBulletTextStyles(variant)}>
                      {text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            {buttons && !buttons.length ? null : (
              <Box sx={styles.btnContainer}>
                {buttons?.map((btn, idx) => (
                  <Link
                    href={btn?.href || ''}
                    target={btn?.target || '_self'}
                    key={`${btn?.label || ''}${idx}`}
                  >
                    <Button
                      variant={btn?.variant}
                      color="primary"
                      sx={styles.btnStyles}
                    >
                      {btn?.label || ''}
                    </Button>
                  </Link>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={styles.desktopImg}>
          <Box sx={getImgLayoutStyles(variant)}>
            <Image
              src={img}
              alt={imgAltText}
              width="0"
              height="0"
              style={{ width: '100%', height: '100%' }}
              sizes="100vw"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export const FindingHome = React.memo(LibFindingHome);

export default FindingHome;
