import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

const base = {
  padding: {
    xs: `${spacing(2)} ${spacing(1)}`,
    md: `${spacing(2)} ${spacing(4)}`
  },
  position: 'relative',
  height: '100%'
};
const textTitle = {
  marginBottom: spacing(1),
  width: {
    xs: '100%',
    md: '83%',
    mm: '90%',
    lg: '100%'
  }
};
const textSubtitle = {
  marginBottom: spacing(1),
  width: {
    xs: '100%',
    md: '23rem',
    mm: '28rem',
    lg: '34rem'
  }
};
const descriptionText = {
  marginBottom: spacing(1),
  width: {
    xs: '100%',
    md: '23rem',
    mm: '28rem',
    lg: '29rem'
  }
};

const styles: Record<string, SxProps> = {
  base: {
    ...base
  },

  gridRight: {
    flexDirection: {
      xs: 'column-reverse',
      md: 'row'
    }
  },
  gridLeft: {
    flexDirection: {
      xs: 'column-reverse',
      md: 'row-reverse'
    }
  },

  containerText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  containerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    gap: spacing(1)
  },

  titleText: {
    ...textTitle
  },

  subtitleText: {
    ...textSubtitle
  },

  descriptionText: {
    ...descriptionText
  },

  bulletPointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5625rem',
    marginBottom: '1.5625rem',
    gap: '1.875rem'
  },
  bulletPointContainer: {
    display: 'flex',
    gap: '0.9375rem'
  },

  desktopImg: {
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  desktopImgBox: {
    height: '110%',
    width: {
      xs: '115%',
      xl: '100%'
    }
  },

  mainImg: {
    display: {
      xs: 'none',
      md: 'block'
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'top',
    width: '100%',
    height: '92%',
    paddingTop: {
      md: '73%',
      lg: '71%'
    },
    borderRight: 'none'
  },
  mainImgMobile: {
    display: {
      xs: 'block',
      md: 'none'
    },
    mb: { xs: spacing(1), md: '0' },
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    '& img': {
      objectFit: 'cover',
      marginLeft: {
        xs: 'none',
        lg: 'auto'
      },
      width: {
        xs: '100%',
        ml: '95%',
        xl: '80%'
      },
      height: {
        xs: '100%',
        ml: '95%',
        xl: '80%'
      }
    }
  },

  btnContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    '& a': {
      width: {
        xs: '100%',
        sm: 'auto'
      }
    },
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: spacing(1),
    marginTop: spacing(1)
  },
  btnStyles: {
    textTransform: 'none',
    width: {
      xs: '100%',
      sm: 'auto'
    },
    textAlign: 'center'
  },

  bulletPointBox: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    gap: spacing(0.5),
    marginTop: spacing(1),
    '&:not(:last-child)': {
      marginBottom: spacing(1)
    },
    span: {
      marginLeft: '0.75rem'
    }
  },
  bulletText: {
    fontWeight: '400',
    color: palette.grey.main
  },
  mobileImageWrapperRight: {
    marginRight: `-${spacing(1)}`
  },
  mobileImageWrapperLeft: {
    marginLeft: `-${spacing(1)}`
  },
  largeImgRight: {
    width: '100%',
    height: '100%',
    transform: `translateX(${spacing(4)})`,
    '& img': {
      objectFit: 'contain',
      objectPosition: {
        xs: '1.5rem',
        xl: '3rem'
      }
    }
  },
  largeImgLeft: {
    width: '100%',
    height: '100%',
    transform: `translateX(-${spacing(4)})`,
    '& img': {
      objectFit: 'contain',
      objectPosition: {
        xs: '-1.5rem',
        xl: '-3rem'
      }
    }
  }
};

export default styles;
